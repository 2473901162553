import React from 'react'
import Layout from '../components/layout'
import Choose from '../components/Choose/Choose'
import Labs from '../components/Labs/Labs'
import Register from '../components/Register/Register'
import Work from '../components/Work/Work'

const Pharmacies = () => {
  return (
    <Layout>
      <Labs />
      <Choose />
      <Work />
      <Register />
    </Layout>
  )
}

export default Pharmacies;
