import React from 'react';
import "./Register.css";

const Register = () => {
  const [openReg, setReg] = React.useState(false)
  const [openLoc, setLoc] = React.useState(false)
    return (
      <div>
        {/* <h3 style={{textAlign: "center"}} >Register Now</h3> */}
        <h2 style={{textAlign: 'center'}} class="text-3xl text-indigo-800">Register Now</h2>

        <div className="register-box ">
          <form>
            <input type="text" placeholder="Your Name" className="input" />
            <input type="text" placeholder="Business Name (optional)" className="input" />
           
            {/* <select name="gender" className="input">
                <option value="" disabled selected >Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </select> */}
            <div class="flex-row flex">
            <div class=" input text-left">
                <div onClick={() => setReg(!openReg)}>
                  <span class="rounded-md shadow-sm">
                    <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150" id="options-menu" aria-haspopup="true" aria-expanded="true">
                      Registration Type
                      {/* <!-- Heroicon name: chevron-down --> */}
                      <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </span>
                </div>

                {openReg &&  <div class="origin-top-right absolute z-50 mt-2 w-56 rounded-md shadow-lg">
                  <div class="rounded-md bg-white shadow-xs">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    
                      {/* <form method="POST" action="#"> */}
                        <button type="submit" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                          Medical Lab
                        </button>
                        <button type="submit" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                          Pharmacy
                        </button>
                        <button type="submit" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                          Individual (Lab Scientist)
                        </button>
                      {/* </form> */}
                    </div>
                  </div>
                </div>}
              </div>

            <div class="input text-right">
                <div onClick={() => setLoc(!openLoc)}>
                  <span class="rounded-md shadow-sm">
                    <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150" id="options-menu" aria-haspopup="true" aria-expanded="true">
                      Location
                      {/* <!-- Heroicon name: chevron-down --> */}
                      <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </span>
                </div>

                {openLoc &&  <div class="origin-top-right absolute z-50 mt-2 w-56 rounded-md shadow-lg">
                  <div class="rounded-md bg-white shadow-xs">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    
                      {/* <form method="POST" action="#"> */}
                        <button type="submit" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                          Lagos
                        </button>
                      
                      {/* </form> */}
                    </div>
                  </div>
                </div> }
              </div>
              </div>
              {/* <input type="text" placeholder="State" className="input" /> */}

              <input type="text" placeholder="Enter your email" className="input" />
              <input type="text" placeholder="Phone Number" className="input" />
              {/* <input type="date" placeholder="Date" className="input" /> */}
              <input type="text" placeholder="Business Address" class="input w-4/5" />

            
            <input type="submit" value="Register" className="register-btn bg-indigo-800 hover:bg-indigo-600 " />
          </form>
        </div>
      </div>
    )
}

export default Register;